var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_c('button',{staticClass:"btn btn-link ms-2",on:{"click":function($event){return _vm.$router.push({
                name: 'profile-item',
                params: {
                  reference: item.reference,
                },
              })}}},[_vm._v(" "+_vm._s(item.reference)+" ")])]),_c('td',[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('td',[_vm._v(_vm._s(item.date))]),_c('td',{staticClass:"text-warning"},[_vm._v(_vm._s(item.amount))]),_c('td',{staticClass:"text-success"},[_vm._v(" "+_vm._s(item.payrollAmount)+" ")]),_c('td',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(item.remainingAmount)+" ")])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence Nº")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nom")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Date d'item")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Montant")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("M. Payé")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("M. à Régler")])])])
}]

export { render, staticRenderFns }