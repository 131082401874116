<template>
  <div class="">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Nom</th>
          <th scope="col">Date d'item</th>
          <th scope="col">Montant</th>
          <th scope="col">M. Payé</th>
          <th scope="col">M. à Régler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            <button
              class="btn btn-link ms-2"
              @click="
                $router.push({
                  name: 'profile-item',
                  params: {
                    reference: item.reference,
                  },
                })
              "
            >
              {{ item.reference }}
            </button>
          </td>
          <td>
            {{ item.fullName }}
          </td>
          <td>{{ item.date }}</td>
          <td class="text-warning">{{ item.amount }}</td>
          <td class="text-success">
            {{ item.payrollAmount }}
          </td>
          <td class="text-danger">
            {{ item.remainingAmount }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getItems",
    }),
  },
  mounted() {
    this.$store.dispatch("item/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("item/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("item/getAll");
    },

    async listItems(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("item/getTodays");
      } else {
        return await this.$store.dispatch("item/getAll");
      }
    },
    async sort(value) {
      return await this.$store.commit("item/sort", value);
    },
  },
};
</script>
